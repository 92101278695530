
var modal = document.getElementById("myModal");
const overlay = document.getElementById("overlay");
const modalForm = document.getElementById("modalForm");
const modalFormClose = document.getElementById("modalFormClose");


var btn = document.getElementById("modalBtn");


var span = document.getElementsByClassName("close")[0];


btn.onclick = function() {
  modal.style.display = "block";
}


span.onclick = function() {
  modal.style.display = "none";
  overlay.classList.remove("is-show");
  document.body.style.overflow = 'auto';
}


window.onclick = function(event) {
  if (event.target.id === "overlay") {
    modal.style.display = "none";
    overlay.classList.remove("is-show");
    modalForm.style.display = "none";
    modalFormSuccess.style.display = "none";
		modalFormFill.style.display = "none";
		modalFormError.style.display = "none";
    document.body.style.overflow = 'auto';
  }
}

btn.addEventListener("click", () => {
    overlay.classList.add("is-show");
    document.body.style.overflow = 'hidden';
})


window.addEventListener("keydown", (event) => {
    if (event.key == "Escape") {
        span.click()
        modalFormClose.click()
    }
})

const homeLink = document.getElementById("homeLink");
const aboutLink = document.getElementById("aboutLink");
const servicesLink = document.getElementById("servicesLink");
const getInTouchLink = document.getElementById("getInTouchLink");
const mpLink = document.getElementById("mpLink");
const navToogle = document.getElementById("menu-toggle");

homeLink.addEventListener('click', function () {
  navToogle.checked = false;
})

aboutLink.addEventListener('click', function () {
  navToogle.checked = false;
})

servicesLink.addEventListener('click', function () {
  navToogle.checked = false;
})

getInTouchLink.addEventListener('click', function () {
  navToogle.checked = false;
})

mpLink.addEventListener('click', function () {
  navToogle.checked = false;
})